<template>
  <div class="about">
    <div>
      <el-input size="mini" style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button size="mini" @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table
        size="mini"
        highlight-current-row
        @current-change="onchange"
        height="397px"
        :data="tableData"
        style="width: 100%;">
      <el-table-column prop="nickname" label="用户名称" >
      </el-table-column>
      <el-table-column prop="mobile_phone" label="手机号" >
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          small
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/Api"//本页面接口
import {watchEffect,defineComponent} from 'vue';
//本页面接口
export default {
  name: 'brand',
  // setup(props, context) {
  //   /* ------------------------------定义methods ---------------------------------*/
  //   const methods = {
  //     // 父组件调用方法
  //     getListFun:()=>{
  //       console.log('子组件方法执行')
  //       alert('子组件')
  //     },
  //   };
  //   return Object.assign(methods,{props});
  // },
  data(){
    return{
      login:false,
      tableData:[],
      active:'1',
      page:1,
      limit:50,
      total:0,
      keywords:'',
      ruleForm:{},
    }
  },
  created() {
    this.onTable_m()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onchange(val){
      console.log()
      this.$emit('onData',val);
    },
    onTable_m(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
      axios.get(form,'/api/admin/member/memberList?w_type=base').then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable_m()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable_m()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable_m()
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>