<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-button  style="margin-bottom: 10px" size="mini" v-for="(item,i) in SearchStatusArr" :key="i" :class="item.status==status?'onserch':''" type="primary" plain @click="onOrderSerch(item.status)">{{item.title}}</el-button>

    <el-table  height="calc(100vh - 295px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#">
      </el-table-column>
      <el-table-column width="90" prop="member_id" label="ID">
      </el-table-column>
      <el-table-column width="118" prop="nickname" label="昵称">
      </el-table-column>
      <el-table-column width="118" prop="real_name" label="真实姓名">
      </el-table-column>
      <el-table-column width="118" prop="mobile_phone" label="手机号">
      </el-table-column>
      <el-table-column width="168" prop="grade_name" label="分销等级">
      </el-table-column>
      <el-table-column width="168" prop="withdraw_rule_name" label="提现规则">
      </el-table-column>
      <el-table-column prop="status" label="奖励金额">
        <template #default="scope">
         ￥ {{scope.row.amount}}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="清退">
        <template #default="scope">
           <el-tag type="danger" size="mini" v-if="scope.row.is_repeled==1">清退</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="repel_time" width="160" label="清退时间">
      </el-table-column>
      <el-table-column prop="repel_reason" min-width="160" label="清退原因">
      </el-table-column>
      <el-table-column prop="create_time" min-width="160" label="创建时间">
      </el-table-column>
        <el-table-column fixed="right" prop="address" label="操作" width="181">
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary"  @click="openfarral(scope.row)">分销成员</el-button>
                <el-button size="mini" :disabled="scope.row.is_repeled==1" type="primary"  @click="upData(scope.row)">清退</el-button>
              </el-button-group>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    <el-dialog
        title="清退"
        v-model="drawer"
        width="30%"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          class="demo-ruleForm">
        <el-form-item label="清退原因" prop="">
          <el-input  type="textarea" :rows="2" v-model="ruleForm.repel_reason"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align: right">
            <el-button type="primary" v-if="ruleForm.member_id" :loading="login" @click="okAdd('ruleForm',2)">确认清退</el-button>

          </div>
<!--          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>-->
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-drawer
        title="分销成员"
        v-model="farraldrawer"
        size="70%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <Memberefarral :nowData="nowData"></Memberefarral>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import { getArea } from "@/api/common";
import * as axios from "@/api/skuApi"//本页面接口
import Memberefarral from '../../components/memberChild/Memberefarral'
export default {
  name: 'Grade',
  components:{
    Memberefarral
  },
  data(){
    return{
      SearchStatusArr:[
        {
          status: 0,
          title: "正常",
        },
        {
          status: 1,
          title: "清退",
        },

        {
          status: 'all',
          title: "全部",
        },
      ],
      status:0,
      skuValName:'',
      nowData:{
        member_id:'',
      },
      login:false,
      farraldrawer:false,
      drawer:false,
      tableData:[],
      areaArr:[],//城市
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        member_id:'',
        repel_reason:'',
      },
      modeArr:[
        {
          id:0,
          name:'新申请',
        },
        {
          id:1,
          name:'处理中',
        },
        {
          id:2,
          name:'完成',
        },
        {
          id:3,
          name:'失败',
        },
      ],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        start_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        end_date: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  created() {
    this.onTable()
    this.onArea()
    // this.keyupSubmit()
  },
  methods:{
    onOrderSerch(val){
      console.log(val)
      this.page=1
      this.status=val
      this.onTable()
    },
    // keyupSubmit(){
    //   document.onkeydown=e=>{
    //     let _key=window.event.keyCode;
    //     if(_key===13){
    //       this.search()
    //     }
    //   }
    // },
    openfarral(row){
      console.log(row)
      this.nowData.member_id=row.member_id
      console.log(this.nowData.member_id)
      this.farraldrawer=true
    },
    modefun(){
      this.ruleForm.times=0
      this.ruleForm.start_date=''
      this.ruleForm.end_date=''
    },
    onArea(){
        getArea().then((res)=>{
          this.areaArr=res.data
        }).catch((err)=>{

        })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        isquit:this.status,
        keywords:this.keywords,
        w_type:'all',
      }
        axios.getSkuData(form,'/api/admin/member/member_refarral').then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },


    add(){
      this.ruleForm={
        status:0,
      }
      this.drawer=true
    },
    upData(row){
      console.log(row)
      this.ruleForm.member_id=row.member_id
      this.ruleForm.repel_reason=row.repel_reason
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postSkuData(this.ruleForm,'/api/admin/member/withdraw/add').then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postSkuData(this.ruleForm,'/api/admin/member/member_refarral/repel').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }
        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postSkuData({id:row.id},'/api/admin/member/withdraw/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }

            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },
  }
}
</script>
<style lang="less" >
  .onserch{
    background-color:#409eff!important; ;
    color: white!important;
  }
</style>